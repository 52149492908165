<template>
    <div class="pageContainer flexCloumn cbox">
        <el-row class="flexBetween searchBar" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1">
                <el-select size="small" v-model="queryForm.term_id" filterable placeholder="学期" style=""
                    @change="onTermChange">
                    <el-option v-for="(item, index) in termList" :key="index" :label="item.title"
                        :value="item.id"></el-option>
                </el-select>
                <span class="ml10">日期：</span>
                <el-date-picker size="small" v-model="queryForm.date" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                    type="date" placeholder="选择日期">
                </el-date-picker>


                <el-button class="ml10" size="small" type="primary" icon="el-icon-search" style="display:inline-block"
                    @click="getList">查询</el-button>
                <span style="color: #e6a23c;font-size: 14px;font-weight: bold;"><i class="el-icon-warning"></i>
                    双击单元格进行编辑，编辑完成后点击保存</span>
            </div>
            <div class="flexStart">
                <el-button size="small" type="primary" plain style="display:inline-block" icon="el-icon-down"
                    @click="exportExcel">导出
                </el-button>
            </div>
        </el-row>
        <div class="dividerBar"></div>


        <el-table :data="DataList" height="100%" :key="key" stripe border size="small" ref="elTable"
            style="width:fit-content;width:100%;" @cell-dblclick="editItem">
            <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
            <el-table-column prop="room_name" label="基地名称" min-width="260" align="center"></el-table-column>
            <el-table-column prop="jt" label="桌、讲台" min-width="80" align="center"></el-table-column>
            <el-table-column prop="yz" label="椅" min-width="80" align="center"></el-table-column>
            <el-table-column prop="dm" label="地面" min-width="80" align="center"></el-table-column>
            <el-table-column prop="zl" label="纸篓" min-width="80" align="center"></el-table-column>
            <el-table-column prop="mc" label="门窗" min-width="80" align="center"></el-table-column>
            <el-table-column prop="ggsb" label="公共设备" min-width="80" align="center"></el-table-column>
            <el-table-column prop="sxsb" label="实验实习设备、工具、及展示作品" min-width="80" align="center"></el-table-column>
            <el-table-column prop="hb" label="黑板、白板" min-width="80" align="center"></el-table-column>
            <el-table-column prop="fs" label="灯、风扇" min-width="80" align="center"></el-table-column>
            <el-table-column prop="qb" label="墙壁" min-width="80" align="center"></el-table-column>
            <el-table-column prop="score" label="总分" min-width="80" align="center"></el-table-column>
        </el-table>

        <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
            :current-page.sync="page.current_page" :page-sizes="[10, 20, 50, 100]" :page-size="page.per_page"
            layout="total" :total="page.count" style="margin-top: 10px;">
        </el-pagination>

        <!-- <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
            :current-page.sync="page.current_page" :page-sizes="[10, 20, 50, 100]" :page-size="page.per_page"
            layout="total,sizes, prev, pager, next" :total="page.count" style="margin-top: 10px;">
        </el-pagination> -->




        <el-dialog title="编辑" :visible.sync="dialogVisible" custom-class="width_800 cus_dialog" @close="getList"
            :close-on-click-modal="false">
            <el-form :model="EditItem" label-width="120px" :inline="true" style="text-align:left">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="桌、讲台" required label-width="150px">
                            <el-input-number :min="0" :max="10" v-model="EditItem.jt"></el-input-number>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="椅" required label-width="150px">
                            <el-input-number :min="0" :max="10" v-model="EditItem.yz"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地面" required label-width="150px">
                            <el-input-number :min="0" :max="10" v-model="EditItem.dm"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="纸篓" required label-width="150px">
                            <el-input-number :min="0" :max="10" v-model="EditItem.zl"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="门窗" required label-width="150px">
                            <el-input-number :min="0" :max="10" v-model="EditItem.mc"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="公共设备" required label-width="150px">
                            <el-input-number :min="0" :max="10" v-model="EditItem.ggsb"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" jus>
                        <el-form-item label="实验实习设备、工具、及展示作品" required label-width="250px">
                            <el-input-number :min="0" :max="10" v-model="EditItem.sxsb"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="黑板、白板" required label-width="150px">
                            <el-input-number :min="0" :max="10" v-model="EditItem.hb"></el-input-number>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="灯、风扇" required label-width="150px">
                            <el-input-number :min="0" :max="10" v-model="EditItem.fs"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="墙壁" required label-width="150px">
                            <el-input-number :min="0" :max="10" v-model="EditItem.qb"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row style="text-align:right">
                    <el-col :span="20">
                        <el-button type="primary" icon="el-icon-check" style="display:inline-block"
                            @click="saveForm">保存</el-button>
                    </el-col>

                </el-row>

            </el-form>

        </el-dialog>


        <!-- <DeptUser :odata="[EditItem]" v-on:getUser="getUser" v-if="showUserDialog"></DeptUser> -->


        <!-- <el-dialog title="导入" :visible.sync="showImportDialog1" custom-class="cus_dialog" width="20%"
            style="height: 300px;" :close-on-click-modal="false">
            <el-button icon="el-icon-download" type="text">
                <a href="./downloadFiles/实验室导入模板.xlsx" target="_blank"
                    style="text-decoration: none; color: inherit;font-size: 14px;">下载模板</a>
            </el-button>
            <el-upload style="text-align: center;margin-top: 10px;" action="" :http-request="uploadTemp"
                :on-success="uploadFiles" :show-file-list="false">
                <el-button size="small" icon="el-icon-upload2" type="primary">选择文件</el-button>
            </el-upload>
        </el-dialog> -->
    </div>
</template>

<script>

// import DeptUser from '../../../com/deptuser.vue'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';

export default {
    // components: {
    //     DeptUser
    // },
    data() {
        return {
            key: Math.random(),
            queryForm: {
                date: moment().format('YYYY-MM-DD'),
                keyword: "",
                ktype: "course_name"
            },
            dialogVisible: false,
            showUserDialog: false,
            DataList: [],
            page: {
                current_page: 1,
                per_page: 20,
                count: 0,
                pageSizes: [10, 50, 100]
            },
            tableMonths: [],
            EditItem: {

            },

            showImportDialog: false,
            importForm: {},
            termList: [],
            termIdInit: ''
        };
    },

    async mounted() {

        await this.getTerm()
        this.$http.post("/api/get_teacher_info", {

        }).then(res => {
            this.userinfo = res.data;
            this.getList()
            console.log(this.queryForm)
        })

    },
    methods: {

        getTerm() {
            return new Promise(resolve => {
                this.$http.post(`/api/t_sch_terms_list`).then(res => {
                    if (res && res.data.data && res.data.data.length > 0) {
                        this.termList = res.data.data

                        res.data.data && res.data.data.map(a => {
                            // console.log(a)
                            if (this.globalCurrentDate > a.term_start && this.globalCurrentDate < a.term_end) {
                                this.termIdInit = a.id
                                // this.tableMonths = a.months.map(month => parseInt(month.split('-')[1]) + '月');

                                this.queryForm.term_id = this.termIdInit
                                this.importForm.term_id = this.termIdInit
                                console.log(322, this.importForm.term_id)
                            }
                        })
                        if (!this.termIdInit) {
                            this.termIdInit = res.data.data[0].id

                            this.queryForm.term_id = this.termIdInit
                            this.importForm.term_id = this.termIdInit
                        }
                        // console.log(322, this.importForm.term_id)
                    }
                    // this.$forceUpdate()
                    resolve()
                })
            })
        },
        getList() {
            let params = Object.assign({
                page: this.page.current_page,
                pagesize: this.page.per_page,
            }, this.queryForm)
            this.$http.post("/api/t_sch_sysrooms_wsjc_list_all", params).then(res => {
                // console.log(res)
                //this.DataList = res.data.data
                // this.page = res.data.page
                this.DataList = res.data.data
                this.page.count = res.data.length

            })
        },
        handleSizeChange(val) {
            this.page.current_page = 1
            this.page.per_page = val
            this.getList();
        },
        onTermChange(val) {
            this.$forceUpdate();
        },

        paginate(val) {
            this.page.current_page = val
            this.getList();
        },
        // addForm() {
        //     this.EditItem = {
        //         course_name: "",
        //         course_desc: "",
        //         teacher_id: "",
        //         username: ""
        //     }
        //     this.dialogVisible = true
        // },
        saveForm() {
            this.EditItem.score = this.EditItem.dm + this.EditItem.fs + this.EditItem.ggsb + this.EditItem.hb + this.EditItem.jt + this.EditItem.mc + this.EditItem.qb + this.EditItem.sxsb + this.EditItem.yz + this.EditItem.zl;
            this.$http.post("/api/t_sch_sysrooms_wsjc_list_edit", this.EditItem).then(res => {
                this.$message.success("保存成功")
                this.getList()
                this.dialogVisible = false
            })
        },
        editItem(e) {
            this.EditItem = e;

            this.dialogVisible = true
        },
        // deleteItem(e) {
        //     this.$confirm('是否确认删除?', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         this.$http.post('/api/t_sch_syskc_delete', { id: e.id }).then(res => {
        //             this.$message.success('删除成功');
        //             this.getList()
        //         })
        //     })
        // },
        getUser(e) {
            console.log('----------------')
            console.log(e)
            if (e && e.length > 0) {
                this.EditItem.teacher_id = e[0].id
                this.EditItem.username = e[0].username
            }
            this.showUserDialog = false
        },
        showDeptUserDialog() {
            //alert(111)
            this.showUserDialog = true
        },
        uploadFiles(e) {
            console.log(e)
            this.importForm.fileName = e.fileName
            this.importForm.url = e.src
            this.$forceUpdate()
            return
            let loadingInstance = this.$loading({
                lock: true,
                text: "正在导入，请稍等....",
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.3)',
            })
            this.$http
                .post('/api/sys_rooms_import', {
                    url: e.src,
                })
                .then((res) => {
                    this.$message.success("导入成功");
                    if (res.data.errors && res.data.errors.length > 0) {
                        let html = "";
                        for (let msg of res.data.errors) {
                            html +=
                                "<div style='font-size:12px'>" + msg + "</div>";
                        }
                        this.$alert(html, "未导入数据", {
                            dangerouslyUseHTMLString: true,
                        });
                    }
                    this.getList();
                    this.showImportDialog = false;
                    loadingInstance.close()
                });
        },

        exportExcel() {
            // const elTable = this.$refs.elTable;
            // const wb = XLSX.utils.table_to_book(elTable.$el);
            // console.log(wb)
            // const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
            // try {
            //     // 导出Excel
            //     saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'data.xlsx');
            // } catch (e) {
            //     if (typeof console !== 'undefined') console.error(e, wbout);
            // }
            // return wbout;
            this.$http.post("/api/export_sysrooms_wsjc_list_all_excel", this.queryForm).then(res => {
                // console.log(res)
                window.open(res.data.url, "_blank")
            })
        },

        updateTable() {
            this.key = Math.random()
        },

    },
}
</script>

<style scoped="scoped">
.dttable td {
    text-align: center;
}
</style>
